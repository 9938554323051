
import { mapState } from 'vuex';
import { getLastActivity } from '@/utils/localstorage'
import Loader from '@/components/Loader';

export default {
  name: 'Home',
  components: {
    Loader,
  },
  data: () => ({
    lastPhotoData: null,
    lastInspectionData: null,
    lastAssetData: null,
  }),
  computed: {
    ...mapState('assets__new', [
      'getAssetsResult',
      'getAssetsIsLoading',
      'getAssetsError',
    ]),
    ...mapState('inspections__new', [
      'getInspectionsResult',
      'getInspectionsIsLoading',
      'getInspectionsError',
    ]),
    ...mapState('organizations', [
      'getOrganizationsItemResult',
      'getOrganizationsItemIsLoading',
      'getOrganizationsItemError',
    ]),
    ...mapState('users', [
      'getCurrentProfileResult',
      'getCurrentProfileIsLoading',
      'getCurrentProfileError',

      'getUsersItemResult',
      'getUsersItemIsLoading',
      'getUsersItemError',
    ]),
    isLoading() {
      return this.getAssetsIsLoading ||
        this.getInspectionsIsLoading ||
        this.getOrganizationsItemIsLoading ||
        this.getCurrentProfileIsLoading ||
        this.getUsersItemIsLoading;
    },
    welcomeName() {
      return [ this.getUsersItemResult?.first_name, this.getUsersItemResult?.last_name ].filter(x => !!x).join(' ') ||
        this.getUsersItemResult?.username ||
        null;
    },
    organizationAssets() {
      return this.getAssetsResult?.filter(x => x.organization === this.getOrganizationsItemResult?.id) || [];
    },
    organizationInspections() {
      return this.getInspectionsResult?.filter(x => x.organization === this.getOrganizationsItemResult?.id) || [];
    },
    lastAsset() {
      return this.lastAssetData && this.getAssetsResult.find(x => x.id === this.lastAssetData.id) || null;
    },
    lastInspection() {
      return this.lastInspectionData && this.getInspectionsResult.find(x => x.id === this.lastInspectionData.id) || null;
    },
    lastPhoto() {
      return this.lastPhotoData;
    },
  },
  methods: {
      formatDate(date) {
        return new Date(date).toLocaleString();
      },
  },
  beforeMount() {
    this.lastPhotoData = getLastActivity({ type: 'photo' }) || null;
    this.lastInspectionData = getLastActivity({ type: 'inspection' }) || null;
    this.lastAssetData = getLastActivity({ type: 'asset' }) || null;

    if (!this.getAssetsResult && !this.getAssetsIsLoading) {
      this.$store.dispatch('assets__new/getAssets');
    }
    if (!this.getInspectionsResult && !this.getInspectionsIsLoading) {
      this.$store.dispatch('inspections__new/getInspections');
    }
    if (!this.getCurrentProfileResult && !this.getCurrentProfileIsLoading) {
      this.$store.dispatch('users/getCurrentProfile');
    }
  },
  watch: {
    getCurrentProfileResult(newVal) {
      if (newVal) {
        if (this.getUsersItemResult?.id !== newVal.user && !this.getUsersItemIsLoading) {
          this.$store.dispatch('users/getUsersItem', { id: newVal.user });
        }
        if (this.getOrganizationsItemResult?.id !== newVal.organization && !this.getOrganizationsItemIsLoading) {
          this.$store.dispatch('organizations/getOrganizationsItem', { id: newVal.organization });
        }
      }
    },
    getOrganizationsItemResult(newVal) {
      console.log('getOrganizationsItemResult', newVal);
    },
    getUsersItemResult(newVal) {
      console.log('getUsersItemResult', newVal);
    },
  },
}
